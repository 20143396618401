<script>
import Multiselect from "vue-multiselect";
import helpers from "../../../helpers";

import {
  analyticsMethods,
} from "@/state/helpers";

export default {
  data() {
    return {
      
        series: [],
        chartoptions: {
          chart: {
            height: 350,
            type: "area",
            sparkline: {
                enabled: false,
              },
            toolbar: {
              show: false,
            },
          },
          colors: ["#5e40bf"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: 2,
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [20, 100, 100, 100],
            },
          },
          yaxis:{
            labels: {
              show:true
            }
          }          
        },
      
      selection: "all",
      values: [
        {id: 'new_members', label: 'Miembros Nuevos'},
        {id: 'points_delivered', label: 'Puntos Entregados'},
        {id: 'points_fulfillment', label: 'Puntos canjeados'},
        {id: 'fulfillments', label: 'Canjes'},
        
      ],
      auxValue: {id: 'new_members', label: 'Miembros Nuevos'},
      groupButtonSelected: 'day'
    };
  },
  components:{Multiselect},
  props: {
    query : String
  },
  mounted(){
    const self = this;

    this.chartoptions.xaxis = {
        labels: {
          formatter: function (valor) {
            return self.parseDateWithLocale(new Date(valor),self.getDateConfigByOptions());
          }
        }
      }
      this.chartoptions.yaxis = {
        labels: {
          formatter: function (valor) {
            if(self.auxValue.id =='direct_revenue' || self.auxValue.id =='assisted_revenue'){
              return `$${self.parseMoneyWithLocale(valor)}`;
            }
            else if(self.auxValue.id =='ctr'|| self.auxValue.id =='cr'){
              return `${self.parsePercentage(valor)}%`;
            }else{
              return valor;
            }
            
          }
        }
      }
      this.$refs.TrendsChart.updateOptions(this.chartoptions);
  },
  computed: {
    getSubtitleText(){
      switch(this.auxValue.id){
        case 'new_members':
          return 'Miembros nuevos en el tiempo.'
        case 'points_delivered':
          return 'Puntos entregados en el tiempo.'
        case 'points_fulfillment':
          return 'Puntos canjeados en el tiempo.'
        case 'fulfillments':
          return 'Cantidad de canjes en el tiempo.'
      }
      return ''
    }
  },
  methods: {
    ...analyticsMethods,
    ...helpers,
    getDateConfigByOptions(){
      if(this.groupButtonSelected == 'day'){
        return { month: 'short', day: 'numeric', timeZone: 'UTC'}
      }
      else if(this.groupButtonSelected == 'week'){
        return {month: 'long', day: 'numeric', timeZone: 'UTC'}
      }else{
        return {month: 'long', timeZone: 'UTC'}
      }
    },
    loadData(){
     
      let loader = this.$loading.show();
      switch(this.auxValue.id){
        case 'new_members':
          //this.getRecoSessionsTimeline(q).then((res)=>{
            this.series= [{
              name: this.auxValue.label,
              data:[]
            }]
            
            this.series[0].data = []
            this.series[0].data.sort((a, b) => a[0] - b[0]);

            this.$refs.TrendsChart.updateSeries(this.series,true);
            loader.hide();
          //});
          break;
        case 'points_delivered':
          //this.getRecoImpressionsTimeline(q).then((res)=>{
            this.series= [{
              name: this.auxValue.label,
              data:[]
            }]
            
            this.series[0].data = []//Array.from(res.data, x => [ new Date(x.group_time).getTime() , x.count]);
            this.series[0].data.sort((a, b) => a[0] - b[0]);

            this.$refs.TrendsChart.updateSeries(this.series,true);
            
            loader.hide();
          //});
          break;
        case 'points_fulfillment': 
          //this.getRecoCTRTimeline(q).then((res)=>{
            this.series= [{
              name: this.auxValue.label,
              data:[]
            }]
            
            this.series[0].data = [] //Array.from(res.data, x => [ new Date(x.group_time).getTime() , x.ctr * 100]);
            this.series[0].data.sort((a, b) => a[0] - b[0]);

            this.$refs.TrendsChart.updateSeries(this.series,true);
            
            loader.hide();
          //});
          break;
          case 'fulfillments': 
            //this.getRecoCRTimeline(q).then((res)=>{
              this.series= [{
                name: this.auxValue.label,
                data:[]
              }]
              
              this.series[0].data = []// Array.from(res.data, x => [ new Date(x.group_time).getTime() , x.cr * 100]);
              this.series[0].data.sort((a, b) => a[0] - b[0]);

              this.$refs.TrendsChart.updateSeries(this.series,true);
              
              loader.hide();
            //});
            break;
            
      }
    },
    

    selectButtonFilter(day){
      this.groupButtonSelected = day;
      this.loadData();
    },
    onValueChanged(){
      this.loadData();
    }
  },
  watch:{
    query: {
      immediate: true,
      handler(newVal){

        if(newVal){
          this.loadData();
        }

      }
    }
  }
};
</script>

<template>
  <div class="card-body">
    <div class="d-flex flex-wrap">
      <div class="d-flex flex-column">
        <h5 class="card-title me-2">Trends</h5>
        <p class="small mb-0">{{getSubtitleText}}</p>
      </div>
      
      <div class="ms-auto d-inline-flex">
        <multiselect 
            :options="values"
            :placeholder="''"
            track-by="id" 
            label="label"
            :show-labels="false"
            :allow-empty="false"
            :multiple="false"
            v-model="auxValue"
            @input="onValueChanged()"
            style="width: 300px;"
            class="me-2"
            />
        <div class="btn-group btn-group-sm pl-2 w-70" role="group" style="width: 70%">
          <button type="button" :class="groupButtonSelected =='day' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('day')">{{"Día"}}</button>
          <button type="button" :class="groupButtonSelected =='week' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('week')">{{'Semana'}}</button>
          <button type="button" :class="groupButtonSelected =='month' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('month')">{{'Mes'}}</button>
        </div>
      </div>
    </div>

    <hr class="mb-2" />

    <apexchart
      ref="TrendsChart"
      class="apex-charts"
      dir="ltr"
      height="350"
      :options="chartoptions"
      :series="series.length > 0 ? series : []"
    ></apexchart>
  </div>
</template>
