<script>

import helpers from "../../../helpers";

export default {
  data() {
    return {
      transactions:[
        { id: '3242341-1313-123123',member: 'Juana Barco', date: new Date(), campaign: '10 puntos por compra en APP', points: 100},
        { id: '3242341-1313-123124',member: 'Juan Ramirez', date: new Date(), campaign: '10 puntos por compra en APP', points: 150},
        { id: '3242341-1313-123125',member: 'Federico Gonzalez', date: new Date(), campaign: '10 puntos por compra en APP', points: 100},
        { id: '3242341-1313-123126',member: 'Maria Santana', date: new Date(), campaign: '10 puntos por compra en APP', points: 100},
        { id: '3242341-1313-123127',member: 'Jose Lopez', date: new Date(), campaign: '10 puntos por compra en APP', points: 100},
      ]
    }
  },
  components: {
    
  },
  props: {
    updating: {
      type: Boolean,
    },
  },
  methods: {
    ...helpers
  }
  
};
</script>

<template>
  <div>
    <div class="table-responsive mb-0">
      <table class="table align-middle table-nowrap">
        <thead class="table-light">
          <tr>
            <th class="align-middle">ID</th>
            <th class="align-middle">Miembro</th>
            <th class="align-middle">Date</th>
            <th class="align-middle">Campaña</th>
            <th class="align-middle">Puntos</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in transactions" :key="data.id">
            <td>
              <a href="javascript: void(0);" class="text-body fw-bold">{{
                data.id
              }}</a>
            </td>
            <td>{{ data.member }}</td>
            <td>{{ `${parseDateWithLocale(data.date, {year: 'numeric', month: 'short', day: 'numeric'})} ${parseTimeWithLocale(data.date, {hour: '2-digit', minute:'2-digit'})}`}}</td>
            <td>{{ data.campaign }}</td>
            <td>
              {{ data.points }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>